import React, { useState } from "react";
import axios from "axios";
import styled from "styled-components";
import RingLoader from "react-spinners/ScaleLoader";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";
const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idactividad, setIdactividad] = React.useState([]);

  const toggle = () => setModal(!modal);

  const InitData = () => {
    let data = new FormData();
    data.append("cedulaAlumno", props.cedulaAlumno);
    data.append("idactividad", props.idactividad);
    data.append("actividad", props.actividad);
    data.append("idmateria", props.idmateria);
    data.append("boton", "buscarActividadesEnviadas"); 

    axios({
      method: "post",
      url: "https://institutobilinguelasnaciones.com/backend/actividades.php",
      data: data,
    }).then((res) => {
      setIdactividad(res.data.variable2);
    });
  };

  const eliarchivo = (e, i) => {
    setLoading(true);
    let data = new FormData();
    data.append("idarchivos", e);
    data.append("rutaArchivos", i);
    data.append("boton", "eliminarAdjuntosAlumnos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        setLoading(false);
        if (res.data.variable1) {
          InitData();
        } else {
        }
        //console.log(res);
      });
  };

  React.useEffect(() => {
    InitData();
  }, []);

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size=""
      >
        {loading && (
          <div className="cont-spinner row">
            <div className="spinner col-6">
              <RingLoader
                clasName="spinner"
                sizeUnit={"px"}
                size={160}
                width={19}
                radius={20}
                height={160}
                color={"#48e120"}
                loading={loading}
              />
            </div>
          </div>
        )}
        <ModalHeader toggle={props.onClose}>Archivos Enviados</ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
        >
          {idactividad.map((item, i) => {
            return (
              <Card className="mb-4">
                <CardHeader>
                  <Card className="mb-1">
                    <CardHeader>
                      <Titulo1 className="row">
                        Nombre Archivo: {item.variable2}
                      </Titulo1>
                      <Titulo2 className="row">
                        Fecha Enviado: {item.variable4}
                      </Titulo2>
                    </CardHeader>
                    <CardBody>
                      <a
                        href={item.variable3}
                        target="popup"
                        onClick="window.open(this.href, this.target, 'width=350,height=420'); return false;"
                      >
                        {" "}
                        <img
                          src={item.variable3}
                          height="35px"
                          width="35px"
                        />{" "}
                      </a>
                    </CardBody>
                    <CardFooter>
                      {item.variable6 == "" && (
                        <Boton1
                          onClick={() => {
                            eliarchivo(item.variable1, item.variable9);
                          }}
                        >
                          Eliminar
                        </Boton1>
                      )}
                    </CardFooter>
                  </Card>
                </CardHeader>
                <CardBody>
                  <Card className="mb-1">
                    <CardHeader>
                      {item.variable5 != "0" ? (
                        <Titulo3 className="row">
                          Nota: {item.variable6} / {item.variable5}
                        </Titulo3>
                      ) : (
                        <Titulo3 className="row">
                          Tarea Solo para Documentar.
                        </Titulo3>
                      )}
                      <Titulo4 className="row">{item.variable7}</Titulo4>
                    </CardHeader>
                    <CardBody>
                      {item.variable8 != "" ? (
                        <MensajeTarea>{item.variable8}</MensajeTarea>
                      ) : (
                        <MensajeTarea>Tarea por Comentar</MensajeTarea>
                      )}
                    </CardBody>
                    <CardFooter>
                      {item.variable10 !== "" && (
                        <Titulo6>
                          Correcciones
                          <a
                            href={item.variable10}
                            target="popup"
                            onClick="window.open(this.href, this.target, 'width=350,height=420'); return false;"
                          >
                            {" "}
                            <img
                              src={item.variable10}
                              height="35px"
                              width="35px"
                            />{" "}
                          </a>
                        </Titulo6>
                      )}
                      <Titulo5>Preguntas por el Chat, Gracias</Titulo5>
                    </CardFooter>
                  </Card>
                </CardBody>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          {/* <Button color="primary" onClick={props.onClose}>
            Cerrar
          </Button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;

const Titulo1 = styled.div`
  font-size: 12px;
`;
const Titulo2 = styled.div`
  font-size: 10px;
`;
const Titulo3 = styled.div`
  font-size: 10px;
`;
const Titulo4 = styled.div`
  font-size: 10px;
`;
const Titulo5 = styled.div`
  font-size: 10px;
`;
const Titulo6 = styled.div`
  font-size: 10px;
`;
const MensajeTarea = styled.div`
  font-size: 12px;
`;
const Boton1 = styled.button`
  color: #fff;
  font-size: 12px;
  background-color: #2bbbad;
  border: none;
  text-align: right;
  :hover {
    background-color: red;
  }
`;
