import React, { Component } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import { connect } from "react-redux";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./boletin.css";
import Alert from "../../modal/alert";

class BoletinA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cedulaAlumno: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      icedulaH: [],
      mensajealerta: false,
      loading: false,
      boletaM: "0",
    };
  }
  limpiar = () => {
    this.setState({
      cedulaAlumno: "",
      trimestre: "",
      anoEscolar: moment().format("YYYY"),
      icedulaH: [],
      mensajealerta: false,
      loading: false,
      boletaM: "0",
    });
  };

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChange2 = (e) => {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "cedulaAlumno") {
      this.VerificarGrado(e.target.value);
    }
    if (index === "idtarea" && e.target.value.length > 0) {
      this.consultarAdjuntos(e.target.value);
      this.setState({ enviarOn: true, adjuntarOn: false });
    }
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  VerificarGrado = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("cedulaAlumno", e);
    form.append("boton", "VerificarGrado");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumno = res.data.variable2;
      if (res.data.variable1) {
        this.setState({ boletaM: res.data.variable2 });
      } else {
        this.setState({
          boletaM: res.data.variable2,
        });
      }
    });
  };

  buscarAlumno = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarAlumnos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumno = res.data.variable2;
      this.setState({
        icedulaH: xialumno,
      });
    });
  };

  habilitarBoletin = () => {
    this.setState({ loading: true });
    let form = new FormData();
    form.append("cedulaAlumno", this.state.cedulaAlumno);
    form.append("trimestre", this.state.trimestre);
    form.append("boton", "buscarAB");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/habilitarBoletin.php";
    axios.post(xurl, form, config).then((res) => {
      if (res.data.variable1) {
        console.log(this.state.boletaM);
        if (this.state.boletaM===0 )this.imprimirBoletin();
        if (this.state.boletaM===1 )this.imprimirBM();
        this.setState({ loading: false });
      } else {
        this.setState({
          loading: false,
          mensajealerta: true,
          tituloM: "Menu Boletines",
          cuerpoM:
            "El Boletin Aún no esta Autorizado, Por Favor Comuniquese con Administración gracias...",
        });
      }
    });
  };

  imprimirBM = () => {
    if (
      this.state.cedulaAlumno.length > 0 &&
      this.state.trimestre.length > 0
    ) {
      
      let data = new FormData();
      data.append("trimestre", this.state.trimestre);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("anoEscolar", this.state.anoEscolar);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletinMaternalesR.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Imprimir Boletines.",
        cuerpoM: "Debe indicar el Alumno y Trimestre.",
      });
    }
  };

  imprimirBoletin = () => {
    if (this.state.cedulaAlumno.length > 0 && this.state.trimestre.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("anoEscolar", this.state.anoEscolar);
      data.append("trimestre", this.state.trimestre);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/boletin2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Reportes",
        cuerpoM: "Debe seleccionar a el Alumno gracias...",
        listaOn: false,
      });
    }
  };

  a = () => {};
  componentDidMount() {
    // this.buscarAnioEscolar();
    // this.cargarFechas();
    // this.buscarProfesor();
    this.buscarAlumno();
  }
  render() {
    return (
      <div className="cont-servi-principalx row">
        <div className="eslogan1su1 col-12">
          <div className="titulo-cxc ">
            <p>GENERAR BOLETIN. </p>
          </div>

          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}

          <div className="historia-contenidoant">
            <div className="form-groupev col-12">
              <div className="row">
                <div className="cont-personal col-12">
                  <div className="contenedorcedula1f col-3">
                    <select
                      id="cedulaAlumno"
                      name="cedulaAlumno"
                      value={this.state.cedulaAlumno}
                      onChange={this.onChange2.bind(this)}
                    >
                      <option>-- Seleccione el Alumno --</option>
                      {this.state.icedulaH.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedorcedula1f col-2">
                    <select
                      id="trimestre"
                      name="trimestre"
                      value={this.state.trimestre}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccionar Trimestre --</option>
                      <option value="1">1ER. TRIMESTRE</option>
                      <option value="2">2DO. TRIMESTRE</option>
                      <option value="3">3ER. TRIMESTRE</option>
                    </select>
                  </div>
                </div>
                <div className="contenedorcedula1f col-12">
                  <div className="acciones">
                    <button
                      className="boton"
                      onClick={this.habilitarBoletin.bind(this)}
                    >
                      Imprimir Boletin
                    </button>
                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

export default connect(mapStateToProps)(BoletinA);
