//dependencias
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

//estilos
import "./SideBarAction.css";
import Alert from "../../modal/alert";
//import Chat from "../Chat";
//componentes
import { outCliente1, outToken } from "../../accion/accion";

class SideBarAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acordion1: false,
      acordion2: false,
      acordion3: false,
      acordion4: false,
      acordion5: false,
      opcionMenu: "",
      menuOn: false,
      mensajealerta: false,
    };
    this.verificar = this.verificar.bind(this);
  }
  verificar(e) {
    // console.log([e.target.checked])
    if (e.target.checked === true) {
      this.setState({ [e.target.getAttribute("id")]: true });
    } else {
      this.setState({ [e.target.getAttribute("id")]: false });
    }
  }

  cerrarsesion() {
    axios
      .get(
        "https://institutobilinguelasnaciones.com/backend/eliminarTokenAcudiente.php/?pas=" +
          this.props.usuario1.password +
          "&tok=" +
          this.props.token.token
      )
      .then((res) => {
        let tokenp = res.data.tokenEli;

        if (tokenp === true) {
          //console.log('resp-token - '+tokenp)

          this.props.outCliente1();
          this.props.outToken();
        } else {
          //console.log('Usuario no Tiene Token');
        }
      })
      .catch((err) => {
        //console.log('error - '+err.response.statu);
        if (err.response.status === 404) {
          //console.log('el usuario no existe ')
        }
      });
  }
  opmenu1 = () => {
    this.setState({ opcionMenu: "1", menuOn: true });
  };
  opmenu2 = () => {
    this.setState({ opcionMenu: "2", menuOn: true });
  };

  cerrarMenu = () => {
    this.setState({ opcionMenu: "", menuOn: false });
  };

  prueba() {
    return <Redirect to="/medicamento" />;
  }

  verificarReload = () => {
    let data = new FormData();
    data.append("cedula", this.props.usuario1.password);
    data.append("boton", "acudiente");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/consultarIP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Principal",
            cuerpoM:
              "Se Reiniciara el Sistema en 5 segundos ya que existe una nueva version, gracias.",
          });
          setTimeout(() => {
            window.location.reload();
          }, 7000);
        } else {
        }
      });
  };
  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  componentDidMount() {
    this.verificarReload();
  }

  render() {
    //console.log('nivel - '+this.props.usuario1.nivel);
    //console.log('usuario: '+this.props.usuario1.usuario);
    return (
      <div>
        {this.state.mensajealerta === true && (
          <Alert
            tituloMensaje={this.state.tituloM}
            cuerpoMensaje={this.state.cuerpoM}
            open={this.state.mensajealerta}
            handleClose={this.handleClose}
          />
        )}
        <div className="contenedor-menus">
          <li className="item">
            <Link to="#" className="btn-menu">
              Menu <i className="icono fas fa-bars"></i>
            </Link>
          </li>
          <div className="menu">
            <ul>
              <span className="btn1">Menu Principal</span>

              <li className="item">
                <label className="btn" htmlFor="acordion1">
                  <i className="icono izquierda fas fa-file-medical" />
                  Administrativo
                  {this.state.acordion1 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion1"
                  checked={this.state.acordion1}
                  onChange={this.verificar}
                />

                <div className="smenu" id="informe">
                  <ul>
                    <li>
                      <Link to="/pago">Reportar Pago</Link>
                    </li>
                    <li>
                      <Link to="/cxc">Consultar Deuda</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="item">
                <label className="btn" htmlFor="acordion2">
                  <i className="icono izquierda far fa-edit" />
                  Academico
                  {this.state.acordion2 === true ? (
                    <i className="icono-derecha-up fas fa-chevron-up" />
                  ) : (
                    <i className="icono derecha fas fa-chevron-down" />
                  )}
                </label>
                <input
                  type="checkbox"
                  id="acordion2"
                  checked={this.state.acordion2}
                  onChange={this.verificar}
                />

                <div className="smenu" id="listado">
                  <ul>
                    <li>
                      <Link to="/reporteP">Consulta de Agenda</Link>
                    </li>

                    <li>
                      <Link to="/subirTareas">Enviar Asignaciones</Link>
                    </li>

                    {/* <li>
                      <Link to="/consultarNotas">Consultar Notas</Link>
                    </li> */}
                    <li>
                      <Link to="/expedienteNotas">Expediente Notas</Link>
                    </li>
                    <li>
                      <Link to="/boletin">Boletin Escolar</Link>
                    </li>
                  </ul>
                </div>
              </li>

              <li className="item">
                <Link
                  to="/"
                  className="btn"
                  onClick={this.cerrarsesion.bind(this)}
                >
                  <i className="icono izquierda fas fa-sign-out-alt"></i>
                  Cerrar Sesión Sistema
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};

const mapDispatchToProps = {
  outCliente1,
  outToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarAction);
