import React, { Component } from 'react';

//componentes
import SubirTareas from './subirTareas'

class Index extends Component {

    render(){
        return(
            <div>
                <SubirTareas />
            </div>
        );
    }

}
export default Index;