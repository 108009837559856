import React, { Component } from "react";
import axios from "axios";
import FeatherIcon from "feather-icons-react";
import RingLoader from "react-spinners/ScaleLoader";
import { Link } from "react-router-dom";

//componentes
import "./adjuntarTareas.css";
import Alert from "../../modal/alert";
class AdjuntarArchivosI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [],
      identificacion: "",
      paso: false,
      btnOK: false,
      mensaje: "",
      loading: false,
      enviadosOn: false,
    };
  }

  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  limpiar = () => {
    this.setState({
      botonOn: true,
      mensajealerta: false,
      rutasArchivos: "",
      irutas: [], 
      identificacion: "",
      paso: false,
      mensaje: "",
      loading: false,
    });
  };

  consultarAdjuntos() {
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("cedulaAlumno", this.props.cedulaAlumno);
    data.append("idactividad", this.props.idactividad);
    data.append("boton", "consultarAdjuntosAlumnos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
          });
        } else {
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  }

  eliarchivo = (e, i) => {
    //console.log(e);
    this.setState({
      loading: true,
    });
    let data = new FormData();
    data.append("idarchivos", e);
    data.append("rutaArchivos", i);
    data.append("boton", "eliminarAdjuntosAlumnos");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.data.variable1) {
          this.consultarAdjuntos();
        } else {
          this.setState({
            paso: true,
            mensaje: "Hubo un Error los Archivos no Fueron Adjuntados",
          });
        }
        //console.log(res);
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };
  
  Upload = (e) => {
    //console.log(e.target.files)
    this.setState({
      loading: true,
    });
    const form_data = new FormData();

    let name_fvar = "";
    for (let x = 0; x < e.target.files.length; x++) {
      name_fvar = "file_" + String(x + 1);
      form_data.append(name_fvar, e.target.files[x]);
      //console.log(name_fvar)
    }
    form_data.append("cedulaAlumno", this.props.cedulaAlumno);
    form_data.append("idactividad", this.props.idactividad);
    form_data.append("boton", "GuardarAdjuntosAlumnos");
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/subirTareas.php";
    axios
      .post(xurl, form_data, config)

      .then((res) => {
        this.setState({
          loading: false,
          enviadosOn: true,
          mensajealerta: true,
          tituloM: "Menu Enviar Tareas a la Institución.",
          cuerpoM: "Los Archivos fueron enviados de forma correcta.",
        });
        if (res.data.variable1) {
          // alert ('Los ArDocumentos fueron enviados exitosamente');
          let xrutas = res.data.variable2;
          this.setState({
            irutas: xrutas,
          });
        } else {
          if (res.data.variable3 > 0) {
            this.setState({
              paso: true,
              mensaje: res.data.variable2,
            });
          } else {
            this.setState({
              paso: true,
              mensaje:
                "Es posible que uno o mas archivos no fueron guardados en el servidor",
            });
          }
        }
        //console.log(res);
      });
  };

  ClickUpload = (e) => {
    document.getElementById("upload-file").click();
  };

  componentDidMount() {
    //this.consultarAdjuntos()
  }

  render() {
    return (
      <div className="cont-servi-principal row">
        <div className="eslogan1su1 col-12">
          <div>
            <p className="titulosu1">
              Modulo para Adjuntar Archivos de Correo.
            </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}
          {this.state.loading && (
            <div className="cont-spinner row">
              <div className="spinner col-6">
                <RingLoader
                  clasName="spinner"
                  sizeUnit={"px"}
                  size={160}
                  width={19}
                  radius={20}
                  height={160}
                  color={"#48e120"}
                  loading={this.state.loading}
                />
              </div>
            </div>
          )}

          <div className="contenido1 row">
            <div className="form-groupef1 col-12">
              <div className="cont-buscar1 row">
                <div
                  className="identificacionr2 col-12"
                  onClick={this.ClickUpload.bind(this)}
                >
                  <p className="subirtexto1">
                    Haz click para adjuntar tus archivos
                  </p>
                  <br />
                  <FeatherIcon icon="upload" size="30px" stroke="#999" />
                  <input
                    onChange={this.Upload.bind(this)}
                    style={{ display: "none" }}
                    id="upload-file"
                    type="file"
                    multiple
                  />
                </div>
              </div>
              {this.state.enviadosOn && (
                <div className="preview row">
                  <div className="titulo-notificacion col-12">
                    <p className="titulosu1">
                      Archivos Enviados a la Institución.
                    </p>
                  </div>
                  {this.state.irutas.map((item, i) => {
                    return (
                      <div className="cont-foto1">
                        <span
                          className="cerrar"
                          onClick={this.eliarchivo.bind(
                            this,
                            item.variable1,
                            item.variable3
                          )}
                        >
                          X
                        </span>{" "}
                        {item.variable2}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AdjuntarArchivosI;
