import React, { Component } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./cxc.css";

import Alert from "../../modal/alert";

class CxC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      meses: [],
      anos: [],
      nalumno: [],
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      habersaldo: "",
      debesaldo: "",
      saldo: "",
      saldoAnterior: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      icursos: [],
      igrados: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      status: "",
      datosChequeOn: false,
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
      anioEscolar: "",
      fechaEscolar: [],
      icedulaH: [],
      nivel:'',
      montoNC:'',
    };
  }

  limpiar = () => {
    this.setState({
      identificacionp1: "",
      tokenVerificar: "",
      opnivel: "",
      acudiente: "",
      alumno: "",
      referencia: "",
      banco: "",
      oppago: "",
      nombreper: "",
      fecha: "",
      monto: "",
      btnEnviarOn: false,
      ifechamedi: [],
      nalumno: [],
      listaOn: false,
      mensajealerta: false,
      correo: "",
      codigo3: "",
      codigo4: "",
      pantalla1On: false,
      fecharegistro: "",
      idpago: "",
      verplanillaOn: false,
      tbusqueda: "",
      identificacionp1: "",
      cedula: "",
      habersaldo: "",
      debesaldo: "",
      saldoAnterior: "",
      procesoOn: false,
      flotadorPDOn: false,
      flotadorMOn: false,
      flotadorPCaOn: false,
      flotadorPCOn: false,
      flotadorVOn: false,
      flotadorREOn: false,
      recibos: [],
      cursoa: "",
      grado: "0",
      modificarSaldoOn: false,
      reversarPagoOn: false,
      idcxc: "",
      montoDebe: "",
      montoAbono: "",
      montoModificar: "",
      idcontrolPago: "",
      status: "",
      concepto: "",
      origen: "",
      modificarMensualidadOn: false,
      mensualidad: "",
      inscripcion: "",
      idmensualidad: "",
      nivel:'',
      montoNC:'',
    });
  };

  cargarFechas = () => {
    let data = new FormData();
    data.append("boton", "cargarFechas");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportarPago.php";
    axios.post(xurl, data, config).then((res) => {
      //console.log("paso "+res.data.variable2)
      let xifechamedi1 = res.data.variable1;
      let xifechamedi2 = res.data.variable2;
      this.setState({
        meses: xifechamedi1,
        anos: xifechamedi2,
      });
    });
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };


  onChange(e) {
    let index = e.target.getAttribute("id");
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (index === "cedulaAlumno") this.buscarPlanillaIndividual(e.target.value);
  }

  onChange2(e) {
    let index = e.target.value;
    let curso = this.state.icurso[index].variable1;
    //let concepto= this.state.icurso[index].variable2
    this.setState({
      curso: curso,
    });
  }

  onChange3(e) {
    console.log(e.target.value);
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarCXC1(this.state.cedula, e.target.value);
  }

  onChange4(e) {
    //console.log(e.target.value)
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  InitData = () => {
    let data = new FormData();
    data.append("clave", this.props.usuario1.password);
    data.append("boton", "buscarAlumno");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/chat.php";
    axios.post(xurl, data, config).then((res) => {
      this.setState({
        cedulaAlumno: res.data.variable1,
      });
      this.buscarPlanillaIndividual(res.data.variable1);
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          anioEscolar: res.data.variable2,
          fechaEscolar: res.data.variable3,
          verplanillaOn: true,
        });
      }
    });
  };

  buscarPlanillaIndividual = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", e);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable13 + " " + res.data.variable14,
                status: res.data.variable133,
                nivel: res.data.variable134,
                verplanillaOn: true,
                cursoa: res.data.variable39,
                grado: res.data.variable40,
              });
              this.buscarCXC1(res.data.variable4);
              this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                acudiente: res.data.variable25 + " " + res.data.variable26,
                status: res.data.variable133,
                nivel: res.data.variable134,
                //verplanillaOn: true,
              });
              this.buscarCXC(res.data.variable4);
              this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarCXC1 = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("anio", moment().format("YYYY"));
    data.append("nivel", this.state.nivel);
    data.append("boton", "buscarCXCxAnio");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            saldo: res.data.variable3,
            debesaldo: res.data.variable4,
            habersaldo: res.data.variable5,
            saldoAnterior: res.data.variable6,
          });
        } else {
          this.setState({
            nalumno: [],
          });
        }
      });
  };

  buscarCXC = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("nivel", this.state.nivel);
    data.append("boton", "buscarCXC");
    

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            saldo: res.data.variable3,
            debesaldo: res.data.variable4,
            habersaldo: res.data.variable5,
            saldoAnterior: res.data.variable6,
          });
        } else {
          this.setState({
            nalumno: [],
          });
        }
      });
  };

  handleClick = (e) => {
    //console.log(e.target.id);
    let idCXC = 0;
    idCXC = e.target.getAttribute("data-valor");
    //console.log(idCXC);

    e.preventDefault();
    if (idCXC > 0) {
      //console.log(e.target.id);
      this.descripcionPago(e.target.id);
    }
  };

  proceso = (e) => {
    if (this.state.procesoOn) {
      this.setState({ procesoOn: false });
    } else {
      this.setState({
        procesoOn: true,
        idcxc: e,
      });
    }
  };

  edoCuenta = () => {
    if (this.state.cedula.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedula);
      data.append("alumno", this.state.alumno);
      data.append("cursoa", this.state.cursoa);
      data.append("grado", this.state.grado);

      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/edoCuenta.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu de Cuentas por Cobrar",
        cuerpoM: "Debe haber un Alumno para realizar el Reporte gracias...",
        listaOn: false,
      });
    }
  };

  datosCheque = () => {
    if (this.state.datosChequeOn) {
      this.setState({ datosChequeOn: false });
    } else {
      this.setState({ datosChequeOn: true });
    }
  };

  descripcionPago = (e) => {
    //console.log(e)
    let data = new FormData();
    data.append("idcxc", e);
    data.append("boton", "descripcionPago");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            datosChequeOn: true,
            oppago: res.data.variable2,
            banco: res.data.variable3,
            referencia: res.data.variable4,
            concepto: res.data.variable5,
            monto: res.data.variable6,
            fecha: res.data.variable7,
            origen: res.data.variable8,
            // mensajealerta: true,
            // tituloM: 'Menu Movimiento de Pago.',
            // cuerpoM: res.data.variable2,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Movimiento de Pago.",
            cuerpoM: res.data.variable2,
          });
        }
      });
  };

  cerrarMensualidad = () => {
    if (this.state.modificarMensualidadOn) {
      this.setState({ modificarMensualidadOn: false });
    } else {
      this.setState({ modificarMensualidadOn: true });
    }
  };

  modificarMensu = () => {
    let data = new FormData();
    data.append("idmensualidad", this.state.idmensualidad);
    data.append("mensualidad", this.state.mensualidad);
    data.append("inscripcion", this.state.inscripcion);
    data.append("boton", "modificarMensualidad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM: "El registro se modifico con exito.",
          });
        } else {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM: "No se pudo modificar el registro, verifique por favor.",
          });
        }
      });
  };

  modificarMensualidad = () => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedula);
    data.append("boton", "buscarMensualidad");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarMensualidadOn: true,
            mensualidad: res.data.variable2,
            inscripcion: res.data.variable3,
            idmensualidad: res.data.variable4,
          });
        } else {
          this.setState({
            modificarMensualidadOn: false,
            mensajealerta: true,
            tituloM: "Menu Modificar Mensualidad e Inscripcion.",
            cuerpoM:
              "El estudiante no tiene registros de mensualidad, verifique por favor.",
          });
        }
      });
  };

  modificarmonto = () => {
    let data = new FormData();
    data.append("idcxc", this.state.idcxc);
    data.append("boton", "buscarMonto");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl = "https://institutobilinguelasnaciones.com/backend/cxc2.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable4);
        //console.log(res.data.variable3);
        if (res.data.variable1) {
          this.setState({
            modificarSaldoOn: true,
            montoDebe: res.data.variable2,
            montoAbono: res.data.variable3,
            montoModificar: res.data.variable4,
            idcontrolPago: res.data.variable5,
            procesoOn: false,
          });
        } else {
          this.setState({
            procesoOn: false,
            mensajealerta: true,
            tituloM: "Menu Estado de Cuenta Alumno.",
            cuerpoM: "Ocurrio un problema, Verifique por favor.",
          });
        }
      });
  };

  buscarAlumno = () => {
    let form = new FormData();
    form.append("clave", this.props.usuario1.password);
    form.append("boton", "buscarAlumnos");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/reportePlanificacion.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumno = res.data.variable2;
      this.setState({
        icedulaH: xialumno,
      });
    });
  };

  recibosEmitido = () => {
    if (this.state.flotadorREOn === true) {
      this.setState({ flotadorREOn: false });
    } else {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.cedula);
      data.append("boton", "consultarReciboG");
      let config = {
        headers: { "Content-Type": "text/html" },
      };
      const xurl = "https://institutobilinguelasnaciones.com/backend/cxc.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xrecibos = res.data.variable2;
            this.setState({
              recibos: xrecibos,
              flotadorREOn: true,
            });
          }
        });
    }
  };

  consultaRecibo = (e) => {
    if (e.length > 0) {
      this.setState({ flotadorREOn: false });
      let data = new FormData();
      data.append("numeroRecibo", e);
      axios({
        method: "post",
        url:
          "https://institutobilinguelasnaciones.com/fpdf/reportes/reciboPago2.php",
        responseType: "arraybuffer",
        data: data,
      }).then(function (response) {
        let blob = new Blob([response.data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL);
      });
    }
  };

  buscarNC = async (e) => {
    //console.log(this.state.opcedulaP)
    let data = new FormData();
    data.append("cedulaA", this.state.cedulaAlumno);
    data.append("boton", "consultarNCPC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/backend/notaCredito.php";
    let res = await axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarProveedores()
          let xfacturasP = res.data.variable2;
          this.setState({
            nocre: xfacturasP,
            montoNC: res.data.variable3,
          });
        }
      });
    this.setState({
      //loading:false,
    });
  };

  ax = () => {};

  componentDidMount() {
    this.buscarAnioEscolar();
    //this.buscargrado();
    //this.InitData();
    this.buscarAlumno();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CUENTAS POR COBRAR AÑO ESCOLAR {moment().format("YYYY")}. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
            />
          )}

          {this.state.verplanillaOn && (
           <div className="cont-cedula row">
              <div className="contenedorcedula1f col-3">
                <select
                  id="cedulaAlumno"
                  name="cedulaAlumno"
                  value={this.state.cedulaAlumno}
                  onChange={this.onChange.bind(this)}
                >
                  <option>-- Seleccione el Alumno --</option>
                  {this.state.icedulaH.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable2}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
          {this.state.flotadorREOn === true && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Listado de Recibos Emitidos
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.recibosEmitido.bind(this)}
                  >
                    X
                  </span>
                </div>
                {/* <span className="titulo-casos">Datos del Pago</span> */}
                <div className="cont-listmedi row">
                  <div className="listmedi col-12">
                    <table className="tablame" border="1">
                      <tbody className="evocuerpo1">
                        {this.state.recibos.map((item, i) => {
                          return (
                            <tr
                              className={i % 2 === 0 ? "odd" : ""}
                              key={item.variable1}
                              id="tabla"
                            >
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultaRecibo.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable2}
                              </td>
                              <td
                                className="renglon"
                                width="10"
                                onClick={this.consultaRecibo.bind(
                                  this,
                                  item.variable1
                                )}
                              >
                                {item.variable1}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>ACUDIENTE: {this.state.acudiente}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc1 col-10">
                <p>ESTUDIANTE: {this.state.alumno}</p>
              </div>
              {/* <div className="titulo-anioescolar">
                <select
                  id="escolar"
                  name="escolar"
                  value={this.state.escolar}
                  onChange={this.onChange3.bind(this)}
                >
                  <option>-- Año Escolar --</option>
                  {this.state.fechaEscolar.map((item, i) => {
                    return (
                      <option key={i} value={item.variable1}>
                        {item.variable1}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="row">
              <div className="titulo-reprecxc ">
                <p>CEDULA: {this.state.cedula}</p>
              </div>
            </div>
          )}
          {this.state.montoNC>0 && (
            <div className="row">
              <div className="titulo-reprecxcNc">
                <p>Monto N/C Pendiente por Aplicar: {this.state.montoNC}</p>
              </div>
            </div>
          )}
          {this.state.verplanillaOn && (
            <div className="cont-listmedi row">
              <div className="listcxc col-12">
                <table className="tablacxc" border="1">
                  <thead>
                    <tr>
                      <th className="titucxc1">FECHA</th>
                      <th className="titucxc2">DESCRIPCIÓN</th>
                      <th className="titucxc3">DEBE</th>
                      <th className="titucxc3">HABER</th>
                      <th className="titucxc3">SALDO</th>
                    </tr>
                  </thead>
                  {this.state.saldoAnterior !== 0 && (
                    <tr className="odd">
                      <td></td>
                      <td className="renglon">SALDO AÑO ESCOLAR ANTERIORES:</td>
                      <td className="totalescxc1">{this.state.debesaldo}</td>
                      <td className="totalescxc1">{this.state.habersaldo}</td>
                      <td className="totalescxc1">
                        {this.state.saldoAnterior}
                      </td>
                    </tr>
                  )}
                  {this.state.nalumno.map((item, i) => {
                    return (
                      <tr
                        className={i % 2 === 0 ? "odd" : "odb"}
                        key={item.variable1}
                        id="tabla"
                      >
                        <td className="totalescxc" width="10">
                          {item.variable2}
                        </td>
                        <td className="renglon" width="10">
                          {item.variable3}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable4}
                        </td>
                        <td
                          className="totalescxc1"
                          width="10"
                          id={item.variable1}
                          data-valor={item.variable5}
                        >
                          {item.variable5}
                        </td>
                        <td className="totalescxc1" width="10">
                          {item.variable6}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="cont-totalescxc">
                    <td className="totalescxc"></td>
                    <td className="totalescxc">******* TOTALES *******</td>
                    <td className="totalescxc1"></td>
                    <td className="totalescxc1"></td>
                    {this.state.saldo < 0 && (
                      <td className="totalescxc2">{this.state.saldo}</td>
                    )}
                    {this.state.saldo >= 0 && (
                      <td className="totalescxc3">{this.state.saldo}</td>
                    )}
                  </tr>
                </table>
              </div>
            </div>
          )}
          
          {this.state.verplanillaOn && (
            <div className="contenedorcedula1 col-12">
              <button
                  className="boton"
                  onClick={this.recibosEmitido.bind(this)}
                >
                  Recibos Emitidos
                </button>
              <Link to="/">
                <button className="boton">Salir</button>
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    usuario1: state.usuario1,
    token: state.token,
  };
};
export default connect(mapStateToProps)(CxC);
