import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardSubtitle,
  CardImg,
  CardTitle,
  CardText,
  CardColumns,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import renderHTML from "react-render-html";

const ModalExample = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const imprSelec = (nombre) => {
    var ficha = document.getElementById(nombre);
    var ventimp = window.open(" ", "popimpr");
    ventimp.document.write(ficha.innerHTML);
    ventimp.document.close();
    ventimp.print();
    ventimp.close();
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={props.onClose}
        scrollable={true}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={props.onClose}>
          Consulta de Planificación
        </ModalHeader>
        <ModalBody
          body
          inverse
          style={{ backgroundColor: "#bbbbbb", borderColor: "#333" }}
          id="seleccion"
        >
          {props.someHTML2.map((item, i) => {
            return (
              <Card className="mb-4">
                <CardHeader>{renderHTML(item.variable3)}</CardHeader>
                <CardBody>
                  {/* <CardTitle tag="h5">Special Title Treatment</CardTitle> */}
                  <CardText>{renderHTML(item.variable2)}</CardText>
                  <CardText>{renderHTML(item.variable7)}</CardText>
                  <CardText>{renderHTML(item.variable5)}</CardText>
                  <CardText>{renderHTML(item.variable4)}</CardText>
                </CardBody>
              </Card>
            );
          })}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={() => imprSelec("seleccion")}>
            Imprimir texto
          </Button>
          {/* <Button color="primary" onClick={props.imprimir}> 
            Imprimir
          </Button> */}
          <Button color="primary" onClick={props.onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalExample;
